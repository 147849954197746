import { DOCUMENT_MAIN_TYPE, MODEL_TYPE } from '../constants/index.jsx'

const PURCHASE_DOCUMENTS = [
  MODEL_TYPE.PURCHASE_QUOTE,
  MODEL_TYPE.PURCHASE_ORDER,
  MODEL_TYPE.PURCHASE_RECEIPT,
  MODEL_TYPE.PURCHASE_INVOICE,
  MODEL_TYPE.PURCHASE_RETURN_ORDER,
  MODEL_TYPE.PURCHASE_RETURN_RECEIPT,
  MODEL_TYPE.PURCHASE_CREDIT_MEMO,
  MODEL_TYPE.PURCHASE_RETAIL_TRANSACTION,
  MODEL_TYPE.PURCHASE_RETAIL_REFUND_TRANSACTION,
  MODEL_TYPE.PURCHASE_FINANCE_CHARGE
]

const SALES_DOCUMENTS = [
  MODEL_TYPE.SALES_OPPORTUNITY,
  MODEL_TYPE.SALES_QUOTE,
  MODEL_TYPE.SALES_ORDER,
  MODEL_TYPE.SALES_SHIPMENT,
  MODEL_TYPE.SALES_INVOICE,
  MODEL_TYPE.SALES_RETURN_ORDER,
  MODEL_TYPE.SALES_RETURN_SHIPMENT,
  MODEL_TYPE.SALES_CREDIT_MEMO,
  MODEL_TYPE.SALES_FINANCE_CHARGE
]

const SALES_RETAIL_DOCUMENTS = [
  MODEL_TYPE.SALES_RETAIL_TRANSACTION,
  MODEL_TYPE.SALES_RETAIL_REFUND_TRANSACTION,
  MODEL_TYPE.SALES_RETAIL_ON_HOLD_TRANSACTION
]

const SERVICE_DOCUMENTS = [
  MODEL_TYPE.SERVICE_QUOTE,
  MODEL_TYPE.SERVICE_CONTRACT,
  MODEL_TYPE.SERVICE_ORDER,
  MODEL_TYPE.SERVICE_SHIPMENT,
  MODEL_TYPE.SERVICE_INVOICE
]

export const DOCUMENT_MAIN_HELPER = {
  PURCHASE_DOCUMENTS,
  SALES_DOCUMENTS,
  SALES_RETAIL_DOCUMENTS,
  SERVICE_DOCUMENTS,
  ALL_DOCUMENTS: PURCHASE_DOCUMENTS.concat(SALES_DOCUMENTS).concat(SALES_RETAIL_DOCUMENTS)
}

export const DOCUMENT_MAIN_MAPPER = {
  [DOCUMENT_MAIN_TYPE.PURCHASE]: {
    documents: PURCHASE_DOCUMENTS
  },
  [DOCUMENT_MAIN_TYPE.SALES]: {
    documents: SALES_DOCUMENTS
  },
  [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: {
    documents: SALES_RETAIL_DOCUMENTS
  },
  [DOCUMENT_MAIN_TYPE.SERVICE]: {
    documents: SERVICE_DOCUMENTS
  }
}
