import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Lookup } from 'devextreme-react/lookup'
import { RequiredRule, Validator } from 'devextreme-react/validator'
import { FormSetup } from '../../../constants'
import { useTranslation } from 'react-i18next'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import Button from 'devextreme-react/button'
import { usePage } from '@inertiajs/react'
import { SquareArrowOutUpRight } from 'lucide-react'
import { renderToStaticMarkup } from 'react-dom/server'

const DevExLookup = ({
  errors,
  validationGroup,
  dataField,
  errorField,
  setData,
  valueExpr = 'value',
  displayExpr = 'label',
  showClearButton = true,
  searchEnabled = true,
  value,
  isRequired = false,
  label,
  visible = true,
  dataSource = [],
  addNew = false,
  disabled,
  readOnly,
  formRender,
  processing,
  onSubmit,
  hideCallback,
  isGrid = false,
  cell,
  searchExpr,
  deferRendering = true,
  onContentReady,
  addNewPopupWidth,
  editLink,
  escapeIsLocked = false
}) => {
  const { t } = useTranslation()
  const [editor, setEditor] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const [localValue, setLocalValue] = useState(value)
  const { isLocked: usePageIsLocked, lockEditing: usePageLockEditing } = usePage().props
  const isLocked = !escapeIsLocked && (usePageIsLocked || usePageLockEditing)
  const [updateClearButton, setUpdateClearButton] = useState(true)

  const onEditorInitialized = useCallback((e) => {
    setEditor(e.component)
  }, [])

  useEffect(() => {
    if (editor && showClearButton) {
      const clearButtonExist = editor._$fieldWrapper[0].querySelector('.dx-show-clear-button')

      if (!disabled && !readOnly && !isLocked) {
        if (!clearButtonExist && (isGrid ? localValue : value)) {
          editor._$fieldWrapper[0].appendChild(clearButton())
        }

        if (clearButtonExist && (isGrid ? !localValue : !value)) {
          clearButtonExist.remove()
        }
      } else {
        clearButtonExist && clearButtonExist.remove()
      }

      setUpdateClearButton(false)
    }
  }, [editor, value, localValue, updateClearButton, disabled, readOnly, isLocked])

  let showGridLabel = false

  if (isGrid) {
    const gridEditMode = cell.component?.option('editing')?.mode
    const showLabelModes = ['popup', 'form']

    errors = {}
    label = cell.column.caption
    showGridLabel = gridEditMode ? showLabelModes.indexOf(gridEditMode) !== -1 : false
  }

  const showPopup = useCallback(() => {
    setPopupVisible(true)
    editor.close()
  }, [setPopupVisible, editor])

  const hide = useCallback(() => {
    setPopupVisible(false)

    hideCallback && hideCallback()
  }, [setPopupVisible])

  const clearButton = () => {
    const buttonNode = document.createElement('div')
    buttonNode.classList = 'dx-show-clear-button'
    buttonNode.onclick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      editor.clear()
    }

    buttonNode.innerHTML = `
      <div class="dx-texteditor-buttons-container">
        <span class="dx-clear-button-area"><span class="dx-icon dx-icon-clear"></span></span>
      </div>`

    return buttonNode
  }

  let dropDownOptions = {}
  if (addNew) {
    dropDownOptions = useMemo(() => (
      {
        minWidth: 250,
        wrapperAttr: {
          class: 'add-new-dropdown-wrap'
        },
        toolbarItems: [
          {
            toolbar: 'bottom',
            location: 'before',
            widget: 'dxButton',
            options: {
              text: t('ui.refresh')
            },
            onClick: e => {
              if (editor) {
                editor.getDataSource()
                  .reload()
                  .then(() => {
                    editor.repaint()
                    setUpdateClearButton(true)
                  })
              }
            }
          },
          {
            toolbar: 'bottom',
            location: 'before',
            widget: 'dxButton',
            options: {
              text: t('ui.add_new')
            },
            onClick: e => {
              showPopup()
            }
          }
        ]
      }
    ), [showPopup, editor])
  }

  const errorEditorOptions = isGrid
    ? {}
    : FormSetup.item.baseProps.error(errors ? errors[errorField ?? dataField] : null).editorOptions

  return (
    <div className={`dx-field ${isGrid ? 'dx-field-grid' : ''} ${isRequired ? 'dx-field-item-required' : ''}`}>
      <div className={`dx-field-custom-component ${value && !!editLink && (!disabled && !readOnly && !isLocked) ? 'flex' : ''}`}>
        <Lookup
          {...errorEditorOptions}
          name={dataField}
          value={isGrid ? localValue : value}
          valueExpr={valueExpr}
          displayExpr={displayExpr}
          showClearButton={false}
          searchEnabled={searchEnabled}
          label={isGrid && !showGridLabel ? '' : label}
          dataSource={dataSource}
          wrapItemText
          onInitialized={e => onEditorInitialized(e)}
          onValueChanged={e => {
            if (!e.value || !e.value.length) {
              e.component.option('isValid', true)
            }
          }}
          onValueChange={value => {
            if (isGrid) {
              setLocalValue(value)
              cell.setValue(value)
            } else {
              setData(dataField, value)
            }
          }}
          visible={visible}
          disabled={disabled}
          readOnly={isLocked || readOnly}
          searchExpr={searchExpr}
          dropDownOptions={dropDownOptions}
          deferRendering={deferRendering}
          onContentReady={onContentReady}
          className={`${value && !!editLink && (!disabled && !readOnly && !isLocked) ? 'flex-1 !mr-[36px] max-w-[calc(100%-36px)]' : ''}`}
        >
          {validationGroup &&
            <Validator
              name={label}
              validationGroup={validationGroup}
            >
              {isRequired && <RequiredRule type='required' />}
            </Validator>}
        </Lookup>

        {value && !!editLink && (!disabled && !readOnly && !isLocked) &&
          <Button
            hint={t('ui.edit_record')}
            icon={renderToStaticMarkup(<SquareArrowOutUpRight size={16} />)}
            onClick={() => {
              window.open(editLink, '_blank')
            }}
            stylingMode='text'
            className='translation-textarea-button mt-[6px]'
          />}

        {addNew && popupVisible &&
          <Popup
            width={addNewPopupWidth ?? 360}
            height='auto'
            visible={popupVisible}
            onHiding={hide}
            hideOnOutsideClick
            showCloseButton
            title={`${t('ui.add_new')} - ${label}`}
            wrapperAttr={{
              class: 'add-new-popup-wrap'
            }}
          >

            {formRender}

            <ToolbarItem
              toolbar='bottom'
              location='before'
              cssClass='popup-footer-wrap'
            >
              <Button
                className='mr-2'
                onClick={hide}
                stylingMode='outlined'
                text={t('ui.cancel')}
                disabled={processing}
              />

              <Button
                onClick={(e) => {
                  onSubmit && onSubmit(e, editor, hide)
                }}
                type='success'
                text={t('ui.save')}
                disabled={processing}
              />
            </ToolbarItem>
          </Popup>}
      </div>
    </div>
  )
}

export default DevExLookup
