import React, { createContext, forwardRef, useImperativeHandle, useState } from 'react'
import { LoadPanel } from 'devextreme-react/load-panel'
import { useTranslation } from 'react-i18next'

const InnerAppCtx = createContext({})

export const InnerAppProvider = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const value = { loading, setLoading }

  useImperativeHandle(ref, () => {
    return {
      setLoading
    }
  }, [])

  return (
    <InnerAppCtx.Provider value={value}>
      {value.loading && (
        <LoadPanel
          shadingColor='rgba(0,0,0,0.6)'
          visible
          showIndicator
          shading
          showPane
          wrapperAttr={{
            class: 'load-panel-show-message'
          }}
          message={t('messages.loading_please_wait')}
        />
      )}
    </InnerAppCtx.Provider>
  )
})
