import React, { useCallback, useContext } from 'react'
import ContextMenu, { Position } from 'devextreme-react/context-menu'
import List from 'devextreme-react/list'
import { usePage, router } from '@inertiajs/react'
import { useTranslation } from 'react-i18next'
import { CircleUserRound, LogOut, MapPin, UserRound, Paintbrush } from 'lucide-react'
import { renderToStaticMarkup } from 'react-dom/server'
import { AppCtx } from '../providers/AppProvider'

export default function UserPanel ({ menuMode }) {
  const { auth, app } = usePage().props
  const { t } = useTranslation()
  const { enumConstants, links } = useContext(AppCtx)

  const menuItems = useCallback(() => ([
    {
      text: auth.user.display_name,
      disabled: true
    },
    {
      text: auth.user.email,
      disabled: true
    },
    {
      text: `${t('labels.location')}: ${auth.user.location?.name}`,
      disabled: true,
      visible: !!auth.user.location_id
    },
    {
      text: t('labels.profile'),
      icon: renderToStaticMarkup(<UserRound size={14} />),
      onClick: () => router.visit(links.administration.profile.manage),
      url: links.administration.profile.manage,
      beginGroup: true
    },
    {
      text: t('labels.update_location'),
      icon: renderToStaticMarkup(<MapPin size={14} />),
      onClick: () => router.visit(links.administration.profile.location.manage),
      visible: auth.user.document_visibility_type === enumConstants.document_visibility_types.SELECTED_LOCATIONS,
      url: links.administration.profile.location.manage
    },
    {
      text: t('ui.clear_session'),
      icon: renderToStaticMarkup(<Paintbrush size={14} />),
      onClick: () => {
        window.localStorage.clear()
        router.visit(links.administration.profile.clearSession)
      }
    },
    {
      text: t('auth.logout'),
      icon: renderToStaticMarkup(<LogOut size={14} />),
      onClick: () => router.visit(links.logout)
    },
    {
      text: `${t('ui.version')}: ${app.version}`,
      disabled: true,
      beginGroup: true
    }
  ]), [auth, app.locale])

  const userImage = auth.user && auth.user.image
    ? auth.user.image.extra_small
    : `data:image/svg+xml;utf8,${renderToStaticMarkup(<CircleUserRound size={12} />)}`

  return (
    <div className='user-panel'>
      <div className='user-info'>
        <div className='image-container'>
          <div
            style={{
              // background: `url(${auth.user.avatarUrl}) no-repeat #fff`,
              backgroundImage: `url('${userImage}')`,
              backgroundColor: '#FFFFFF',
              backgroundSize: 'cover'
            }}
            className='user-image'
          />
        </div>
        <div className='user-name hidden sm:block'>
          <p className='font-bold'>{auth.user.display_name}</p>
          <p>{auth.user.email}</p>
        </div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems()}
          target='.user-panel-button'
          showEvent='dxclick'
          width={200}
          cssClass='user-menu'
          itemRender={(item) => {
            if (item.url) {
              return (
                <a
                  className='!text-inherit dx-item-content dx-menu-item-content !p-0'
                  href={item.url}
                  onClick={e => {
                    e.preventDefault()

                    router.visit(item.url)
                  }}
                >
                  {!!item.icon &&
                    <i className='dx-icon dx-svg-icon' dangerouslySetInnerHTML={{ __html: item.icon }} />}

                  <span className='dx-menu-item-text'>{item.text}</span>
                </a>
              )
            }

            return (
              <>
                {!!item.icon &&
                  <i className='dx-icon dx-svg-icon' dangerouslySetInnerHTML={{ __html: item.icon }} />}

                <span className='dx-menu-item-text'>{item.text}</span>
              </>
            )
          }}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className='dx-toolbar-menu-action' items={menuItems()} />
      )}
    </div>
  )
}
