import React, { createContext, useEffect, useRef, useState } from 'react'
import enumOptions from '../../enumOptions.json'
import enumConstants from '../../enumConstants.json'
import links from '../../links.json'
import i18n, { t } from 'i18next'
import { InnerAppProvider } from './InnerAppProvider.jsx'

export const AppCtx = createContext({})

export const AppProvider = ({ children, settings }) => {
  const innerProviderRef = useRef()

  const [language, setLanguage] = useState(i18n.language)
  const [isFormDirty, setIsFormDirty] = useState(false)

  useEffect(() => {
    const handleNavigation = (event) => {
      const link = event.target.closest('a')

      if (!link || !link.hasAttribute('href') || link.hasAttribute('target')) {
        return
      }

      if (isFormDirty) {
        const userConfirmed = window.confirm(t('ui.unsaved_changes'))

        if (!userConfirmed) {
          event.preventDefault()
          event.stopPropagation()
        } else {
          setIsFormDirty(false)
        }
      }
    }

    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault()
        event.stopPropagation()
        event.returnValue = t('ui.unsaved_changes')
        return true
      }
    }

    const handlePopState = (event) => {
      // back button in browser, popstate event is not cancellable
      if (isFormDirty) {
        setIsFormDirty(false)
      }
    }

    document.addEventListener('click', handleNavigation, true)
    window.addEventListener('beforeunload', handleBeforeUnload, { capture: true })
    window.addEventListener('popstate', handlePopState, { capture: true })

    return () => {
      document.removeEventListener('click', handleNavigation, true)
      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true })
      window.removeEventListener('popstate', handlePopState, { capture: true })
    }
  }, [isFormDirty])

  const enumOptionsWithTranslations = Object.assign({}, ...Object.entries(enumOptions)
    .map(([k, v]) => {
      return ({
        [k]: v.map(item => ({
          ...item,
          label: t(item.label)
        }))
      })
    }))

  const value = {
    enumOptions: enumOptionsWithTranslations,
    enumConstants,
    settings,
    language,
    setLanguage,
    links,
    setIsFormDirty,
    startLoading: (val) => {
      innerProviderRef.current.setLoading(true)
    },
    endLoading: (val) => {
      innerProviderRef.current.setLoading(false)
    }
  }

  return (
    <AppCtx.Provider value={value}>
      {children}
      <InnerAppProvider ref={innerProviderRef} />
    </AppCtx.Provider>
  )
}
