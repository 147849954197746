import { can, route } from '../../utils'
import {
  LayoutDashboard,
  Folder,
  Box,
  Users,
  FolderCheck,
  Warehouse,
  ShoppingBag,
  Receipt,
  Calculator,
  ChartNoAxesCombined,
  Building2,
  Wallet,
  Settings,
  Computer,
  FolderOpenDot,
  Wrench,
  Headset,
  CircleGauge,
  Banknote,
  Factory,
  Car,
  Archive,
  Megaphone
} from 'lucide-react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Trans } from 'react-i18next'

export const buildNavigation = (links, auth, url) => {
  const currentUrl = url.current.startsWith('/', 0) ? url.current : `/${url.current}`

  const navigation = [
    {
      text: <Trans i18nKey='labels.dashboard' />,
      path: '/',
      icon: renderToStaticMarkup(<LayoutDashboard size={16} color='#009965' />),
      show: true
    },
    // Settings
    {
      text: <Trans i18nKey='modules.application' />,
      icon: renderToStaticMarkup(<Settings size={16} color='#00A8E8' />),
      items: [
        // Modules
        {
          text: <Trans i18nKey='modules.module_groups.modules' />,
          path: links.application.modules.index,
          show: can(auth, 'view_modules')
        },
        // Settings
        {
          text: <Trans i18nKey='modules.module_groups.settings' />,
          path: links.application.settings.index,
          show: can(auth, 'view_settings')
        }
      ]
    },

    // Administration
    {
      text: <Trans i18nKey='modules.administration' />,
      icon: renderToStaticMarkup(<Folder size={16} color='#C70039' />),
      items: [
        // Company
        {
          text: <Trans i18nKey='labels.company' />,
          path: links.administration.company.edit,
          show: can(auth, 'edit_company')
        },
        // Users
        {
          text: <Trans i18nKey='modules.module_groups.users' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.roles' />,
              path: links.administration.roles.index,
              show: can(auth, 'view_roles')
            },
            {
              text: <Trans i18nKey='labels.users' />,
              path: links.administration.users.index,
              show: can(auth, 'view_users'),
              new: {
                path: links.administration.users.create,
                show: can(auth, 'create_users')
              }
            }
          ]
        },
        // Localization
        {
          text: <Trans i18nKey='labels.localization' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.countries' />,
              path: links.administration.countries.index,
              show: can(auth, 'view_countries')
            },
            {
              text: <Trans i18nKey='modules.module_groups.cities' />,
              path: links.administration.cities.index,
              show: can(auth, 'view_cities')
            },
            {
              text: <Trans i18nKey='modules.module_groups.post_codes' />,
              path: links.administration.postCodes.index,
              show: can(auth, 'view_post_codes')
            },
            {
              text: <Trans i18nKey='modules.module_groups.regions' />,
              path: links.administration.regions.index,
              show: can(auth, 'view_regions')
            },
            {
              text: <Trans i18nKey='modules.module_groups.municipalities' />,
              path: links.administration.municipalities.index,
              show: can(auth, 'view_municipalities')
            },
            {
              text: <Trans i18nKey='modules.module_groups.territories' />,
              path: links.administration.territories.index,
              show: can(auth, 'view_territories')
            },
            {
              text: <Trans i18nKey='modules.module_groups.languages' />,
              path: links.administration.languages.index,
              show: can(auth, 'view_languages')
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.banks' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.banks' />,
              path: links.administration.banks.index,
              show: can(auth, 'view_banks')
            },
            {
              text: <Trans i18nKey='modules.module_groups.bank_accounts' />,
              path: links.administration.bankAccounts.index,
              show: can(auth, 'view_bank_accounts')
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.currencies' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.currencies' />,
              path: links.administration.currencies.index,
              show: can(auth, 'view_currencies')
            },
            {
              text: <Trans i18nKey='labels.currency_exchange_rate' />,
              path: links.administration.currencyExchangeRates.index,
              show: can(auth, 'view_currencies')
            }
          ]
        },
        {
          text: <Trans i18nKey='modules.module_groups.finance' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.remittances' />,
              path: links.administration.remittances.index,
              show: can(auth, 'view_remittances')
            },
            {
              text: <Trans i18nKey='modules.module_groups.customs_tariffs' />,
              path: links.administration.customsTariffs.index,
              show: can(auth, 'view_customs_tariffs')
            },
            {
              text: <Trans i18nKey='labels.personal_tax' />,
              items: [
                {
                  text: <Trans i18nKey='labels.categories' />,
                  path: links.administration.personalTaxCategories.index,
                  show: can(auth, 'view_personal_tax_categories')
                },
                {
                  text: <Trans i18nKey='labels.groups' />,
                  path: links.administration.personalTaxGroups.index,
                  show: can(auth, 'view_personal_tax_groups')
                }
              ]
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.unit_of_measures' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.unit_of_measures' />,
              path: links.administration.unitOfMeasures.index,
              show: can(auth, 'view_unit_of_measures')
            },
            {
              text: <Trans i18nKey='modules.module_groups.measurement_groups' />,
              path: links.administration.measurementGroups.index,
              show: can(auth, 'view_measurement_groups')
            }
          ]
        },
        {
          text: <Trans i18nKey='modules.module_groups.locations' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.locations' />,
              path: links.administration.locations.index,
              show: can(auth, 'view_locations')
            },
            {
              text: <Trans i18nKey='modules.module_groups.res_centers' />,
              path: links.administration.responsibilityCenters.index,
              show: can(auth, 'view_res_centers')
            },
            {
              text: <Trans i18nKey='modules.module_groups.cost_centers' />,
              path: links.administration.costCenters.index,
              show: can(auth, 'view_cost_centers')
            }
          ]
        },
        // Payment
        {
          text: <Trans i18nKey='labels.payment_2' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.payment_methods' />,
              path: links.administration.paymentMethods.index,
              show: can(auth, 'view_payment_methods')
            },
            {
              text: <Trans i18nKey='modules.module_groups.payment_terms' />,
              path: links.administration.paymentTerms.index,
              show: can(auth, 'view_payment_terms')
            }
          ]
        },
        // Shipment
        {
          text: <Trans i18nKey='labels.shipment' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.shipment_agents' />,
              path: links.administration.shipmentAgents.index,
              show: can(auth, 'view_shipment_agents')
            },
            {
              text: <Trans i18nKey='modules.module_groups.shipment_methods' />,
              path: links.administration.shipmentMethods.index,
              show: can(auth, 'view_shipment_methods')
            },
            {
              text: <Trans i18nKey='modules.module_groups.transport_methods' />,
              path: links.administration.transportMethods.index,
              show: can(auth, 'view_transport_methods')
            },
            {
              text: <Trans i18nKey='modules.module_groups.address_book' />,
              path: links.administration.addressBook.index,
              show: can(auth, 'view_address_book')
            }
          ]
        },
        // VAT Groups
        {
          text: <Trans i18nKey='labels.vat_groups' />,
          items: [
            {
              text: <Trans i18nKey='menu.products' />,
              path: links.administration.vatProductPostingGroups.index,
              show: can(auth, 'view_vat_product_posting_groups')
            },
            {
              text: <Trans i18nKey='menu.accounts' />,
              path: links.administration.vatBusinessPostingGroups.index,
              show: can(auth, 'view_vat_business_posting_groups')
            },
            {
              text: <Trans i18nKey='modules.module_groups.vat_posting_setups' />,
              path: links.administration.vatPostingSetups.index,
              show: can(auth, 'view_vat_posting_setups')
            }
          ]
        },
        // Posting groups
        {
          text: <Trans i18nKey='labels.posting_groups' />,
          items: [
            {
              text: <Trans i18nKey='labels.products' />,
              path: links.administration.productPostingGroups.index,
              show: can(auth, 'view_product_posting_groups')
            },
            {
              text: <Trans i18nKey='labels.accounts' />,
              path: links.administration.businessPostingGroups.index,
              show: can(auth, 'view_business_posting_groups')
            },
            {
              text: <Trans i18nKey='labels.general_posting_setups' />,
              path: links.administration.generalPostingSetups.index,
              show: can(auth, 'view_general_posting_setups')
            }
          ]
        },
        // Discount Groups
        {
          text: <Trans i18nKey='labels.discount_groups' />,
          items: [
            {
              text: <Trans i18nKey='labels.products' />,
              path: links.administration.productDiscountGroups.index,
              show: can(auth, 'view_product_discount_groups')
            },
            {
              text: <Trans i18nKey='modules.account' />,
              path: links.administration.accountDiscountGroups.index,
              show: can(auth, 'view_account_discount_groups')
            }
          ]
        },
        // Attributes
        {
          text: <Trans i18nKey='modules.module_groups.attributes' />,
          path: links.administration.attributes.index,
          show: can(auth, 'view_attributes')
        },
        // Numbering series
        {
          text: <Trans i18nKey='modules.module_groups.numbering_series' />,
          path: links.administration.numberingSeries.index,
          show: can(auth, 'view_numbering_series'),
          new: {
            path: links.administration.numberingSeries.create,
            show: can(auth, 'create_numbering_series')
          }
        },
        // Model Life Cycles
        {
          text: <Trans i18nKey='labels.model_life_cycles' />,
          path: links.administration.modelLifeCycles.index,
          show: can(auth, 'view_model_life_cycles')
        },
        {
          text: <Trans i18nKey='modules.module_groups.sales_person_purchasers' />,
          path: links.administration.salesPersonPurchasers.index,
          show: can(auth, 'view_sales_person_purchasers')
        }
      ]
    },
    {
      text: <Trans i18nKey='labels.products' />,
      icon: renderToStaticMarkup(<Box size={16} color='#9B59B6' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.product' />,
          path: links.products.index,
          show: can(auth, 'view_products'),
          new: {
            path: route(links.products.create, { 'product?': null }),
            show: can(auth, 'create_products')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.brand' />,
          path: links.products.brands.index,
          show: can(auth, 'view_brands')
        },
        {
          text: <Trans i18nKey='modules.module_groups.manufacturers' />,
          path: links.products.manufacturers.index,
          show: can(auth, 'view_manufacturers')
        },
        {
          text: <Trans i18nKey='modules.module_groups.product_categories' />,
          path: links.products.categories.index,
          show: can(auth, 'view_product_categories'),
          new: {
            path: links.products.categories.create,
            show: can(auth, 'create_product_categories')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.product_price_histories' />,
          path: links.products.productPriceHistories.index,
          show: can(auth, 'view_product_price_histories')
        },
        {
          text: <Trans i18nKey='modules.module_groups.discounts' />,
          path: links.products.discounts.index,
          show: can(auth, 'view_discounts'),
          new: {
            path: links.products.discounts.create,
            show: can(auth, 'create_discounts')
          }
        }
      ]
    },
    {
      text: <Trans i18nKey='labels.accounts' />,
      icon: renderToStaticMarkup(<Users size={16} color='#FFD700' />),
      items: [
        {
          text: <Trans i18nKey='labels.accounts' />,
          path: links.accounts.index,
          show: can(auth, 'view_accounts'),
          new: {
            path: links.accounts.create,
            show: can(auth, 'create_accounts')
          }
        },
        {
          text: <Trans i18nKey='labels.contacts' />,
          path: links.accounts.contacts.index,
          show: can(auth, 'view_contacts'),
          new: {
            path: links.accounts.contacts.create,
            show: can(auth, 'create_contacts')
          }
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.hr' />,
      icon: renderToStaticMarkup(<FolderCheck size={16} color='#B0E0E6' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.employees' />,
          path: route(links.hr.employees.index),
          show: can(auth, 'view_employees'),
          new: {
            path: links.hr.employees.create,
            show: can(auth, 'create_employees')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.organizational_structure' />,
          path: route(links.hr.organizationalStructures.index),
          show: can(auth, 'view_organizational_structure')
        },
        {
          text: <Trans i18nKey='labels.affiliation' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.nationalities' />,
              path: links.hr.nationalities.index,
              show: can(auth, 'view_nationalities')
            },
            {
              text: <Trans i18nKey='modules.module_groups.religions' />,
              path: links.hr.religions.index,
              show: can(auth, 'view_nationalities')
            },
            {
              text: <Trans i18nKey='modules.module_groups.citizenship' />,
              path: links.hr.citizenship.index,
              show: can(auth, 'view_citizenship')
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.employment' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.job_positions' />,
              items: [
                {
                  text: <Trans i18nKey='menu.categories' />,
                  path: links.hr.jobTitleCategories.index,
                  show: can(auth, 'view_job_title_categories')
                },
                {
                  text: <Trans i18nKey='modules.module_groups.job_titles' />,
                  path: links.hr.jobTitles.index,
                  show: can(auth, 'view_job_titles')
                }
              ]
            },
            {
              text: <Trans i18nKey='menu.employment_contracts' />,
              path: links.hr.employmentContracts.index,
              show: can(auth, 'view_employment_contracts')
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.education' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.education' />,
              path: links.hr.educations.index,
              show: can(auth, 'view_educations')
            },
            {
              text: <Trans i18nKey='menu.education_degrees' />,
              path: links.hr.educationDegrees.index,
              show: can(auth, 'view_education_degrees')
            },
            {
              text: <Trans i18nKey='modules.module_groups.bachelor_degrees' />,
              path: links.hr.bachelorDegrees.index,
              show: can(auth, 'view_bachelor_degrees')
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.qualifications' />,
          items: [
            {
              text: <Trans i18nKey='labels.categories' />,
              path: links.hr.qualificationCategories.index,
              show: can(auth, 'view_qualification_categories')
            },
            {
              text: <Trans i18nKey='modules.module_groups.qualifications' />,
              path: links.hr.qualifications.index,
              show: can(auth, 'view_qualifications')
            }
          ]
        },
        {
          text: <Trans i18nKey='modules.module_groups.organizational_units' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.organizational_units' />,
              path: links.hr.organizationalUnits.index,
              show: can(auth, 'view_organizational_units')
            },
            {
              text: <Trans i18nKey='modules.module_groups.organizational_unit_types' />,
              path: links.hr.organizationalUnitTypes.index,
              show: can(auth, 'view_organizational_unit_types')
            }
          ]
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.warehouse' />,
      icon: renderToStaticMarkup(<Warehouse size={16} color='#FA8072' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.physical_inventory' />,
          path: links.warehouses.physicalInventories.index,
          show: can(auth, 'view_physical_inventory'),
          new: {
            path: links.warehouses.physicalInventories.create,
            show: can(auth, 'create_physical_inventory')
          }
        },
        {
          text: <Trans i18nKey='labels.transfer' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.transfer_orders' />,
              path: route(links.warehouses.transferOrders.index, { transferTypeBind: 'transfer-order' }),
              show: can(auth, 'view_transfer_order'),
              new: {
                path: route(links.warehouses.transferOrders.create, { transferTypeBind: 'transfer-order' }),
                show: can(auth, 'create_transfer_order')
              }
            },
            {
              text: <Trans i18nKey='labels.transfer_order_shipments' />,
              path: route(links.warehouses.transferOrders.index, { transferTypeBind: 'transfer-order-shipment' }),
              show: can(auth, 'view_transfer_order')
            },
            {
              text: <Trans i18nKey='labels.transfer_order_receipts' />,
              path: route(links.warehouses.transferOrders.index, { transferTypeBind: 'transfer-order-receipt' }),
              show: can(auth, 'view_transfer_order')
            }
          ]
        },
        {
          text: <Trans i18nKey='modules.module_groups.requisition_order' />,
          path: links.warehouses.requisitionOrders.index,
          show: can(auth, 'view_requisition_orders'),
          new: {
            path: links.warehouses.requisitionOrders.create,
            show: can(auth, 'create_requisition_orders')
          }
        },
        {
          text: <Trans i18nKey='labels.journals' />,
          items: [
            {
              text: <Trans i18nKey='labels.assembly' />,
              path: route(links.warehouses.warehouseBOMOrders.index, { warehouseBOMOrderTypeBind: 'warehouse-assembly-orders' }),
              show: can(auth, 'view_warehouse_assembly_order'),
              new: {
                path: route(links.warehouses.warehouseBOMOrders.create, { warehouseBOMOrderTypeBind: 'warehouse-assembly-orders' }),
                show: can(auth, 'create_warehouse_assembly_order')
              }
            },
            {
              text: <Trans i18nKey='labels.disassembly' />,
              path: route(links.warehouses.warehouseBOMOrders.index, { warehouseBOMOrderTypeBind: 'warehouse-disassembly-orders' }),
              show: can(auth, 'view_warehouse_disassembly_order'),
              new: {
                path: route(links.warehouses.warehouseBOMOrders.create, { warehouseBOMOrderTypeBind: 'warehouse-disassembly-orders' }),
                show: can(auth, 'create_warehouse_disassembly_order')
              }
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.wms' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.zones' />,
              path: links.warehouses.zones.index,
              show: can(auth, 'view_zones')
            },
            {
              text: <Trans i18nKey='modules.module_groups.shelving_units' />,
              path: links.warehouses.shelvingUnits.index,
              show: can(auth, 'view_shelving_units')
            },
            {
              text: <Trans i18nKey='modules.module_groups.shelves' />,
              path: links.warehouses.shelves.index,
              show: can(auth, 'view_shelves')
            },
            {
              text: <Trans i18nKey='modules.module_groups.bins' />,
              path: links.warehouses.bins.index,
              show: can(auth, 'view_bins')
            }
          ]
        },
        {
          text: <Trans i18nKey='labels.reports' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.stock_list' />,
              path: links.warehouses.reports.stockList.index,
              show: can(auth, 'stock_list_report')
            },
            {
              text: <Trans i18nKey='labels.stock_records' />,
              path: links.warehouses.reports.stockRecords.index,
              show: can(auth, 'stock_list_report')
            },
            {
              text: <Trans i18nKey='modules.module_groups.stock_product_card' />,
              path: links.warehouses.reports.stockProductCard.index,
              show: can(auth, 'stock_product_card_report')
            }
          ]
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.travel_orders' />,
      icon: renderToStaticMarkup(<Car size={16} color='#DC143C' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.travel_orders' />,
          path: links.travelOrders.index,
          show: can(auth, 'view_travel_orders'),
          new: {
            path: route(links.travelOrders.create),
            show: can(auth, 'create_travel_orders')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.travel_fuels' />,
          path: links.travelOrders.travelFuels.index,
          show: can(auth, 'view_travel_fuels')
        },
        {
          text: <Trans i18nKey='modules.module_groups.travel_fuels_prices' />,
          path: links.travelOrders.travelFuelsPrices.index,
          show: can(auth, 'view_travel_fuels_prices')
        },
        {
          text: <Trans i18nKey='modules.module_groups.travel_vehicles' />,
          path: links.travelOrders.travelVehicles.index,
          show: can(auth, 'view_travel_vehicles')
        },
        {
          text: <Trans i18nKey='modules.module_groups.travel_reasons' />,
          path: links.travelOrders.travelReasons.index,
          show: can(auth, 'view_travel_reasons')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.purchase' />,
      icon: renderToStaticMarkup(<ShoppingBag size={16} color='#8567ff' />),
      items: [
        {
          text: <Trans i18nKey='labels.purchase_quotes' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-quote' }),
          show: can(auth, 'view_purchase_quote'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-quote' }),
            show: can(auth, 'create_purchase_quote')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_contracts' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-contract' }),
          show: can(auth, 'view_purchase_contract'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-contract' }),
            show: can(auth, 'create_purchase_contract')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_orders' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-order' }),
          show: can(auth, 'view_purchase_order'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-order' }),
            show: can(auth, 'create_purchase_order')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_return_orders' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-return-order' }),
          show: can(auth, 'view_purchase_return_order'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-return-order' }),
            show: can(auth, 'create_purchase_return_order')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_invoices' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-invoice' }),
          show: can(auth, 'view_purchase_invoice'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-invoice' }),
            show: can(auth, 'create_purchase_invoice')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_credit_memos' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-credit-memo' }),
          show: can(auth, 'view_purchase_credit_memo'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-credit-memo' }),
            show: can(auth, 'create_purchase_credit_memo')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_receipts' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-receipt' }),
          show: can(auth, 'view_purchase_receipt'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-receipt' }),
            show: can(auth, 'create_purchase_receipt')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_return_receipts' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-return-receipt' }),
          show: can(auth, 'view_purchase_return_receipt'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-return-receipt' }),
            show: can(auth, 'create_purchase_return_receipt')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.purchase_retail_transactions' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-retail-transaction' }),
          show: can(auth, 'view_purchase_retail_transaction'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-retail-transaction' }),
            show: can(auth, 'create_purchase_return_receipt')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.purchase_retail_refund_transactions' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-retail-refund-transaction' }),
          show: can(auth, 'view_purchase_retail_refund_transaction'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-retail-refund-transaction' }),
            show: can(auth, 'create_purchase_return_receipt')
          }
        },
        {
          text: <Trans i18nKey='labels.purchase_finance_charges' />,
          path: route(links.documents.index, { documentTypeBind: 'purchase-finance-charge' }),
          show: can(auth, 'view_purchase_finance_charge'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'purchase-finance-charge' }),
            show: can(auth, 'create_purchase_finance_charge')
          }
        },
        {
          text: <Trans i18nKey='labels.reports' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.vendor_summary_balance' />,
              path: route(links.documents.reports.purchase.vendorSummaryBalance.index),
              show: can(auth, 'vendor_summary_balance_report')
            },
            {
              text: <Trans i18nKey='modules.module_groups.vendor_detail_balance' />,
              path: route(links.documents.reports.purchase.vendorDetailBalance.index),
              show: can(auth, 'vendor_detail_balance_report')
            }
          ]
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.sales' />,
      icon: renderToStaticMarkup(<Receipt size={16} color='#f7a501' />),
      items: [
        {
          text: <Trans i18nKey='labels.sales_opportunities' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-opportunity' }),
          show: can(auth, 'view_sales_opportunity'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-opportunity' }),
            show: can(auth, 'create_sales_opportunity')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_quotes' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-quote' }),
          show: can(auth, 'view_sales_quote'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-quote' }),
            show: can(auth, 'create_sales_quote')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_contracts' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-contract' }),
          show: can(auth, 'view_sales_contract'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-contract' }),
            show: can(auth, 'create_sales_contract')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_orders' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-order' }),
          show: can(auth, 'view_sales_order'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-order' }),
            show: can(auth, 'create_sales_order')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_return_orders' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-return-order' }),
          show: can(auth, 'view_sales_return_order'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-return-order' }),
            show: can(auth, 'create_sales_return_order')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_invoices' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-invoice' }),
          show: can(auth, 'view_sales_invoice'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-invoice' }),
            show: can(auth, 'create_sales_invoice')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_credit_memos' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-credit-memo' }),
          show: can(auth, 'view_sales_credit_memo'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-credit-memo' }),
            show: can(auth, 'create_sales_credit_memo')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_shipments' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-shipment' }),
          show: can(auth, 'view_sales_shipment'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-shipment' }),
            show: can(auth, 'create_sales_shipment')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_return_shipments' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-return-shipment' }),
          show: can(auth, 'view_sales_return_shipment'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-return-shipment' }),
            show: can(auth, 'create_sales_return_shipment')
          }
        },
        {
          text: <Trans i18nKey='labels.sales_finance_charges' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-finance-charge' }),
          show: can(auth, 'view_sales_finance_charge'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-finance-charge' }),
            show: can(auth, 'create_sales_finance_charge')
          }
        },
        {
          text: <Trans i18nKey='labels.reports' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.customer_summary_balance' />,
              path: route(links.documents.reports.sales.customerSummaryBalance.index),
              show: can(auth, 'customer_summary_balance_report')
            },
            {
              text: <Trans i18nKey='modules.module_groups.customer_detail_balance' />,
              path: route(links.documents.reports.sales.customerDetailBalance.index),
              show: can(auth, 'customer_detail_balance_report')
            }
          ]
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.retail' />,
      icon: renderToStaticMarkup(<Wallet size={16} color='#6aa84f' />),
      items: [
        {
          text: <Trans i18nKey='menu.sales_retail_transactions' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-retail-transaction' }),
          show: can(auth, 'view_sales_retail_transaction'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-retail-transaction' }),
            show: can(auth, 'create_sales_retail_transaction')
          }
        },
        {
          text: <Trans i18nKey='menu.sales_retail_refund_transactions' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-retail-refund-transaction' }),
          show: can(auth, 'view_sales_retail_refund_transaction'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-retail-refund-transaction' }),
            show: can(auth, 'create_sales_retail_refund_transaction')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.sales_retail_on_hold_transactions' />,
          path: route(links.documents.index, { documentTypeBind: 'sales-retail-on-hold-transaction' }),
          show: can(auth, 'view_sales_retail_on_hold_transaction'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'sales-retail-on-hold-transaction' }),
            show: can(auth, 'create_sales_retail_on_hold_transaction')
          }
        },
        {
          text: <Trans i18nKey='labels.reports' />,
          items: [
            {
              text: <Trans i18nKey='labels.product_sales' />,
              path: links.documents.reports.retail.productSales.index,
              show: can(auth, 'product_sales_report')
            },
            {
              text: <Trans i18nKey='labels.payment_type' />,
              path: links.documents.reports.retail.paymentType.index,
              show: can(auth, 'payment_type_sales_report')
            },
            {
              text: <Trans i18nKey='labels.retail_daily_finance_report' />,
              path: links.documents.reports.retail.dfi.index,
              show: can(auth, 'retail_daily_finance_report')
            },
            {
              text: <Trans i18nKey='labels.retail_daily_finance_report_book' />,
              path: links.documents.reports.retail.kdfi.index,
              show: can(auth, 'retail_daily_finance_report')
            },
            {
              text: <Trans i18nKey='modules.module_permissions.retail_daily_discount_report' />,
              path: links.documents.reports.retail.dailyDiscounts.index,
              show: can(auth, 'retail_daily_discount_report')
            }
          ]
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.salary' />,
      icon: renderToStaticMarkup(<Banknote size={16} color='#2f80fa' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.salary_calculation' />,
          path: route(links.salary.salaryCalculation.index),
          show: can(auth, 'view_salary_calculation'),
          new: {
            path: links.salary.salaryCalculation.create,
            show: can(auth, 'create_salary_calculation')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.employees' />,
          path: route(links.salary.employees.index),
          show: can(auth, 'view_employees'),
          new: {
            path: links.hr.employees.create,
            show: can(auth, 'create_employees')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.salary_liability_types' />,
          path: route(links.salary.salaryLiabilityTypes.index),
          show: can(auth, 'view_salary_liability_types')
        },
        {
          text: <Trans i18nKey='modules.module_groups.salary_obligatory_types' />,
          path: route(links.salary.salaryObligatoryTypes.index),
          show: can(auth, 'view_salary_obligatory_types')
        },
        {
          text: <Trans i18nKey='modules.module_groups.monthly_salary_parameters' />,
          path: route(links.salary.monthlySalaryParameters.index),
          show: can(auth, 'view_monthly_salary_parameters')
        },
        {
          text: <Trans i18nKey='modules.module_groups.yearly_salary_parameters' />,
          path: route(links.salary.yearlySalaryParameters.index),
          show: can(auth, 'view_yearly_salary_parameters')
        },
        {
          text: <Trans i18nKey='modules.module_groups.statistical_groups' />,
          path: route(links.salary.statisticalGroups.index),
          show: can(auth, 'view_statistical_groups')
        },
        {
          text: <Trans i18nKey='modules.module_groups.deduction_groups' />,
          path: route(links.salary.deductionGroups.index),
          show: can(auth, 'view_deduction_groups')
        },
        {
          text: <Trans i18nKey='modules.module_groups.employee_deductions' />,
          path: route(links.salary.employeeDeductions.index),
          show: can(auth, 'view_employee_deductions')
        },
        {
          text: <Trans i18nKey='modules.module_groups.salary_hour_types' />,
          path: route(links.salary.salaryHourTypes.index),
          show: can(auth, 'view_salary_hour_types')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.accounting' />,
      icon: renderToStaticMarkup(<Calculator size={16} color='#f54e00' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.chart_of_accounts' />,
          path: route(links.accounting.chartOfAccounts.index),
          show: can(auth, 'view_chart_of_accounts')
        },
        {
          text: <Trans i18nKey='labels.journals' />,
          path: route(links.accounting.journals.index),
          show: can(auth, 'view_journal_headers'),
          new: {
            path: links.accounting.journals.create,
            show: can(auth, 'create_journal_headers')
          }
        },
        {
          text: <Trans i18nKey='labels.reports' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.chart_of_account_card' />,
              path: links.accounting.reports.chartOfAccountsCard.index,
              show: can(auth, 'chart_of_account_card_report')
            },
            {
              text: <Trans i18nKey='modules.module_groups.gross_balance' />,
              path: links.accounting.reports.grossBalance.index,
              show: can(auth, 'gross_balance_report')
            },
            {
              text: <Trans i18nKey='modules.module_groups.income_statement' />,
              path: links.accounting.reports.incomeStatement.index,
              show: can(auth, 'income_statement_report')
            },
            {
              text: <Trans i18nKey='modules.module_groups.closing_balance' />,
              path: links.accounting.reports.closingBalance.index,
              show: can(auth, 'closing_balance_report')
            },
            {
              text: <Trans i18nKey='modules.module_groups.analytical_gl_journal' />,
              path: links.accounting.reports.analyticalGLJournal.index,
              show: can(auth, 'analytical_gl_journal_report')
            }
          ]
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.archive' />,
      icon: renderToStaticMarkup(<Archive size={16} color='#b62ad9' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.archive_headers' />,
          path: route(links.archive.index),
          show: can(auth, 'view_archive_headers'),
          new: {
            path: links.archive.create,
            show: can(auth, 'create_archive_headers')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.archive_signs' />,
          path: route(links.archive.archiveSigns.index),
          show: can(auth, 'view_archive_signs')
        },
        {
          text: <Trans i18nKey='modules.module_groups.archive_sub_signs' />,
          path: route(links.archive.archiveSubSigns.index),
          show: can(auth, 'view_archive_sub_signs')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.finance' />,
      icon: renderToStaticMarkup(<ChartNoAxesCombined size={16} color='#30abc6' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.bank_statements' />,
          path: route(links.finance.bankStatements.index),
          show: can(auth, 'view_bank_statement_headers'),
          new: {
            path: links.finance.bankStatements.create,
            show: can(auth, 'create_bank_statement_headers')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.payments' />,
          path: route(links.finance.payments.index, { paymentTypeBind: 'payment' }),
          show: can(auth, 'view_payment'),
          new: {
            path: route(links.finance.payments.create, { paymentTypeBind: 'payment' }),
            show: can(auth, 'create_payment')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.payouts' />,
          path: route(links.finance.payments.index, { paymentTypeBind: 'payout' }),
          show: can(auth, 'view_payout'),
          new: {
            path: route(links.finance.payments.create, { paymentTypeBind: 'payout' }),
            show: can(auth, 'create_payout')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.cashier_receives' />,
          path: route(links.finance.cashiers.index, { cashierTypeBind: 'cashier-receives' }),
          show: can(auth, 'view_cashier_receives'),
          new: {
            path: route(links.finance.cashiers.create, { cashierTypeBind: 'cashier-receives' }),
            show: can(auth, 'create_cashier_receives')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.cashier_pays' />,
          path: route(links.finance.cashiers.index, { cashierTypeBind: 'cashier-pays' }),
          show: can(auth, 'view_cashier_pays'),
          new: {
            path: route(links.finance.cashiers.create, { cashierTypeBind: 'cashier-pays' }),
            show: can(auth, 'create_cashier_pays')
          }
        },
        {
          text: <Trans i18nKey='labels.person_payments' />,
          path: links.finance.personPayments.index,
          show: can(auth, 'view_person_payments'),
          new: {
            path: links.finance.personPayments.create,
            show: can(auth, 'create_person_payments')
          }
        },
        {
          text: <Trans i18nKey='labels.reclassification_order' />,
          path: links.finance.reclassificationOrders.index,
          show: can(auth, 'view_reclassification_orders'),
          new: {
            path: links.finance.reclassificationOrders.create,
            show: can(auth, 'create_reclassification_orders')
          }
        },
        {
          text: <Trans i18nKey='labels.foreign_trade_calculation' />,
          path: links.finance.foreignTradeCalculations.index,
          show: can(auth, 'view_foreign_trade_calculations'),
          new: {
            path: links.finance.foreignTradeCalculations.create,
            show: can(auth, 'create_foreign_trade_calculations')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.cash_accounts' />,
          path: route(links.finance.cashAccounts.index),
          show: can(auth, 'view_cash_accounts')
        },
        {
          text: <Trans i18nKey='modules.module_groups.vouchers' />,
          path: route(links.finance.vouchers.index),
          show: can(auth, 'view_vouchers')
        },
        {
          text: <Trans i18nKey='modules.module_groups.product_ledger' />,
          path: links.finance.productLedger.index,
          show: can(auth, 'view_product_ledger')
        },
        {
          text: <Trans i18nKey='modules.module_groups.account_ledger' />,
          path: links.finance.accountLedger.index,
          show: can(auth, 'view_account_ledger')
        },
        {
          text: <Trans i18nKey='labels.reports' />,
          items: [
            {
              text: <Trans i18nKey='modules.module_groups.document_vat_report' />,
              path: route(links.finance.reports.vatReport.index),
              show: can(auth, 'document_vat_report')
            }
          ]
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.fixed_assets' />,
      icon: renderToStaticMarkup(<Building2 size={16} color='#F7DC6F' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.fixed_assets' />,
          path: route(links.fixedAssets.index),
          show: can(auth, 'view_fixed_assets'),
          new: {
            path: links.fixedAssets.create,
            show: can(auth, 'create_fixed_assets')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.small_inventories' />,
          path: route(links.fixedAssets.smallInventories.index),
          show: can(auth, 'view_small_inventories'),
          new: {
            path: links.fixedAssets.smallInventories.create,
            show: can(auth, 'create_small_inventories')
          }
        },
        {
          text: <Trans i18nKey='labels.classes' />,
          path: route(links.fixedAssets.fixedAssetClasses.index),
          show: can(auth, 'view_fixed_asset_classes')
        },
        {
          text: <Trans i18nKey='labels.locations' />,
          path: route(links.fixedAssets.fixedAssetLocations.index),
          show: can(auth, 'view_fixed_asset_locations')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.pos' />,
      icon: renderToStaticMarkup(<Computer size={16} color='#1ABC9C' />),
      items: [
        {
          text: <Trans i18nKey='modules.pos' />,
          path: route(links.pos.index),
          show: can(auth, 'view_pos')
        },
        {
          text: <Trans i18nKey='modules.module_groups.workstations' />,
          path: route(links.pos.workstations.index),
          show: can(auth, 'view_workstations')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.project_management_system' />,
      icon: renderToStaticMarkup(<FolderOpenDot size={16} color='#FF69B4' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.projects' />,
          path: route(links.pms.projects.index),
          show: can(auth, 'view_projects')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.service' />,
      icon: renderToStaticMarkup(<Wrench size={16} color='#ef4444' />),
      items: [
        {
          text: <Trans i18nKey='labels.service_quotes' />,
          path: route(links.documents.index, { documentTypeBind: 'service-quote' }),
          show: can(auth, 'view_service_quote'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'service-quote' }),
            show: can(auth, 'create_service_quote')
          }
        },
        {
          text: <Trans i18nKey='labels.service_contracts' />,
          path: route(links.documents.index, { documentTypeBind: 'service-contract' }),
          show: can(auth, 'view_service_contract'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'service-contract' }),
            show: can(auth, 'create_service_contract')
          }
        },
        {
          text: <Trans i18nKey='labels.service_orders' />,
          path: route(links.documents.index, { documentTypeBind: 'service-order' }),
          show: can(auth, 'view_service_order'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'service-order' }),
            show: can(auth, 'create_service_order')
          }
        },
        {
          text: <Trans i18nKey='labels.service_invoices' />,
          path: route(links.documents.index, { documentTypeBind: 'service-invoice' }),
          show: can(auth, 'view_service_invoice'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'service-invoice' }),
            show: can(auth, 'create_service_invoice')
          }
        },
        {
          text: <Trans i18nKey='labels.service_shipments' />,
          path: route(links.documents.index, { documentTypeBind: 'service-shipment' }),
          show: can(auth, 'view_service_shipment'),
          new: {
            path: route(links.documents.create, { documentTypeBind: 'service-shipment' }),
            show: can(auth, 'create_service_shipment')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.service_zones' />,
          path: route(links.services.serviceZones.index),
          show: can(auth, 'view_service_zones')
        },
        {
          text: <Trans i18nKey='modules.module_groups.service_groups' />,
          path: route(links.services.serviceGroups.index),
          show: can(auth, 'view_service_groups')
        },
        {
          text: <Trans i18nKey='modules.module_groups.symptom_codes' />,
          path: route(links.services.symptomCodes.index),
          show: can(auth, 'view_symptom_codes')
        },
        {
          text: <Trans i18nKey='modules.module_groups.fault_codes' />,
          path: route(links.services.faultCodes.index),
          show: can(auth, 'view_fault_codes')
        },
        {
          text: <Trans i18nKey='modules.module_groups.solution_codes' />,
          path: route(links.services.solutionCodes.index),
          show: can(auth, 'view_solution_codes')
        },
        {
          text: <Trans i18nKey='modules.module_groups.knowledge_bases' />,
          path: route(links.services.knowledgeBases.index),
          show: can(auth, 'view_knowledge_bases')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.production' />,
      icon: renderToStaticMarkup(<Factory size={16} color='#6366f1' />),
      items: [
        {
          text: <Trans i18nKey='labels.production_order' />,
          path: route(links.production.productionOrders.index),
          show: can(auth, 'view_production_orders'),
          new: {
            path: links.production.productionOrders.create,
            show: can(auth, 'create_production_orders')
          }
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.helpdesk' />,
      icon: renderToStaticMarkup(<Headset size={16} color='#f59e0b' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.tickets' />,
          path: route(links.helpDesk.tickets.index),
          show: can(auth, 'view_tickets'),
          new: {
            path: links.helpDesk.tickets.create,
            show: can(auth, 'create_tickets')
          }
        },
        {
          text: <Trans i18nKey='modules.module_groups.help_topics' />,
          path: route(links.helpDesk.helpTopics.index),
          show: can(auth, 'view_help_topics')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.marketing' />,
      icon: renderToStaticMarkup(<Megaphone size={16} color='#10b981' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.campaigns' />,
          path: route(links.marketing.campaigns.index),
          show: can(auth, 'view_campaigns')
        }
      ]
    },
    {
      text: <Trans i18nKey='modules.resources' />,
      icon: renderToStaticMarkup(<CircleGauge size={16} color='#3498DB' />),
      items: [
        {
          text: <Trans i18nKey='modules.module_groups.resources' />,
          path: route(links.resources.index),
          show: can(auth, 'view_resources'),
          new: {
            path: links.resources.create,
            show: can(auth, 'create_resources')
          }
        },
        {
          text: <Trans i18nKey='labels.resources_locations' />,
          path: route(links.resources.resourceLocations.index),
          show: can(auth, 'view_resource_locations')
        },
        {
          text: <Trans i18nKey='labels.resource_skills' />,
          path: route(links.resources.resourceSkill.index),
          show: can(auth, 'view_resource_skills')
        },
        {
          text: <Trans i18nKey='modules.module_groups.resource_groups' />,
          path: route(links.resources.resourceGroups.index),
          show: can(auth, 'view_resource_groups')
        },
        {
          text: <Trans i18nKey='modules.module_groups.skills' />,
          path: route(links.resources.resourceSkills.index),
          show: can(auth, 'view_resource_skills')
        },
        {
          text: <Trans i18nKey='modules.module_groups.skill_groups' />,
          path: route(links.resources.resourceSkillGroups.index),
          show: can(auth, 'view_resource_skill_groups')
        },
        {
          text: <Trans i18nKey='modules.module_groups.skill_levels' />,
          path: route(links.resources.resourceSkillLevels.index),
          show: can(auth, 'view_resource_skill_levels')
        },
        {
          text: <Trans i18nKey='modules.module_groups.work_types' />,
          path: route(links.resources.workTypes.index),
          show: can(auth, 'view_work_types')
        },
        {
          text: <Trans i18nKey='modules.module_groups.resource_prices' />,
          path: route(links.resources.resourcePrices.index),
          show: can(auth, 'view_resource_prices')
        }
      ]
    }
  ]

  filterNavigation(navigation, currentUrl)

  return navigation
}

const filterNavigation = (navigation, currentUrl) => {
  const toSplice = []

  navigation.forEach((n, i) => {
    if (n.items) {
      filterNavigation(n.items, currentUrl)

      n.items.map(i => {
        i.selected = i.path === currentUrl
        i.expanded = i.path === currentUrl
        return i
      })

      n.show = n.items.filter(i => i.show).length > 0
    }

    if (!n.items) {
      n.selected = n.path === currentUrl
      n.expanded = n.path === currentUrl
    }

    if (!n.show) {
      toSplice.push(i)
    }
  })

  toSplice.reverse().forEach(spliceIndex => {
    navigation.splice(spliceIndex, 1)
  })
}
