import React from 'react'
import Header from './Header'
import ToastMessages from './ToastMessages'
import { Toaster } from 'sonner'
import MainContent from './MainContent'

const Layout = (props) => {
  return (
    <div className='app side-nav-outer-toolbar'>
      <Header
        menuToggleEnabled
      />

      <MainContent fullGrid={props.fullGrid}>
        {props.children}
      </MainContent>

      <Toaster
        position='top-right'
        richColors
        duration={5000} // TODO: check much seconds
        closeButton
        expand
      />

      <ToastMessages />
    </div>
  )
}

export default Layout
