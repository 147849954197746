import React, { useEffect } from 'react'
import { usePage } from '@inertiajs/react'
import { toast } from 'sonner'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

const ToastMessages = () => {
  const { t } = useTranslation()
  const { flash } = usePage().props

  const parseMessage = (message) => {
    if (Array.isArray(message)) {
      let buildMessage = ''

      message.forEach((m, i) => {
        buildMessage += `${(i + 1)}. ${m} <br>`
      })

      return buildMessage
    }

    return message
  }

  useEffect(() => {
    if (flash.success) {
      toast.success(t('ui.success'), {
        description: parse(parseMessage(flash.success)),
        descriptionClassName: 'max-h-96 overflow-auto'
      })
    }

    if (flash.error) {
      toast.error(t('ui.error'), {
        description: parse(parseMessage(flash.error)),
        descriptionClassName: 'max-h-96 overflow-auto'
      })
    }

    if (flash.warning) {
      toast.warning(t('ui.warning'), {
        description: parse(parseMessage(flash.warning)),
        descriptionClassName: 'max-h-96 overflow-auto'
      })
    }

    if (flash.info) {
      toast.info(t('ui.info'), {
        description: parse(parseMessage(flash.info)),
        descriptionClassName: 'max-h-96 overflow-auto'
      })
    }
  }, [flash])

  return (
    <></>
  )
}

export default ToastMessages
